<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="delinquents"
      :options.sync="options"
      :server-items-length="totalDelinquents"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Screening Delinquents</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px" persistent>
            <v-card>
              <validation-observer ref="form">
                <form @submit.prevent="onSubmit">
                  <v-row>
                    <v-col cols="11">
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                    </v-col>
                    <v-col cols="1">
                      <v-spacer />
                      <v-btn
                        class="ml-0"
                        dark
                        icon
                        color="pink"
                        large
                        @click="close"
                        ><v-icon>mdi-close-circle</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <img :src="nidaPicture" width="200" height="auto" />
                          <img :src="webcamPicture" width="300" height="auto" />
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="12" sm="6">
                          <v-btn rounded disabled absolute right>{{
                            currentCenter.name
                          }}</v-btn>
                        </v-col>
                      </v-row>
                      <v-card class="mt-2" max-width="1200" outlined raised>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>I. Identification</p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="First Name"
                              >
                                <v-text-field
                                  dense
                                  label="First Name"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.firstName"
                                  name="First Name"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Last Name"
                              >
                                <v-text-field
                                  dense
                                  label="Last Name"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.lastName"
                                  name="Last Name"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Gender"
                              >
                                <v-text-field
                                  dense
                                  label="Gender"
                                  item-text="name"
                                  outlined
                                  v-model="editedDelinquent.gender"
                                  class="mt-n6"
                                  name="Gender"
                                  :error-messages="errors"
                                  color="grey"
                                  disabled
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Marital Status"
                              >
                                <v-text-field
                                  dense
                                  label="Marital Status"
                                  item-text="name"
                                  outlined
                                  v-model="editedDelinquent.maritalStatus"
                                  class="mt-n6"
                                  name="Marital Status"
                                  :error-messages="errors"
                                  color="grey"
                                  disabled
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                                dense
                                label="Father Name"
                                v-model="editedDelinquent.fatherName"
                                type="text"
                                outlined
                                name="Father Name"
                                color="grey"
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                                dense
                                label="Mother Name"
                                type="text"
                                outlined
                                v-model="editedDelinquent.motherName"
                                name="Mother Name"
                                color="grey"
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card class="mt-2" max-width="1200" outlined raised>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>II. Place and Date of Birth</p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="birthProvinces"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.placeOfBirth.province.id
                                "
                                dense
                                color="grey"
                                label="Province"
                                outlined
                                class="mt-n6"
                                filled
                                disabled
                              />
                              <!-- <v-btn color="blue" link x-small class="mt-n12" @click="loadBirthProvinces">Load Provinces</v-btn> -->
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="birthDistricts"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.placeOfBirth.district.id
                                "
                                dense
                                color="grey"
                                label="District"
                                outlined
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="birthSectors"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.placeOfBirth.sector.id
                                "
                                dense
                                color="grey"
                                label="Sector"
                                outlined
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="birthCells"
                                item-text="name"
                                item-value="id"
                                v-model="editedDelinquent.placeOfBirth.cell.id"
                                dense
                                color="grey"
                                label="Cell"
                                outlined
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="birthVillages"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.placeOfBirth.village.id
                                "
                                dense
                                color="grey"
                                label="Village"
                                outlined
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                                v-model="editedDelinquent.dateOfBirth"
                                label="Date of Birth"
                                outlined
                                filled
                                disabled
                                color="grey"
                                class="mt-n6"
                                dense
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card max-width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>III. Place of Residence</p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="residenceProvinces"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.apprehension
                                    .delinquentResidence.province.id
                                "
                                dense
                                color="grey"
                                label="Province"
                                outlined
                                class="mt-n6"
                                filled
                                disabled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="residenceDistricts"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.apprehension
                                    .delinquentResidence.district.id
                                "
                                dense
                                color="grey"
                                label="District"
                                outlined
                                class="mt-n6"
                                filled
                                disabled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="residenceSectors"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.apprehension
                                    .delinquentResidence.sector.id
                                "
                                dense
                                color="grey"
                                label="Sector"
                                outlined
                                class="mt-n6"
                                filled
                                disabled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="residenceCells"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.apprehension
                                    .delinquentResidence.cell.id
                                "
                                dense
                                color="grey"
                                label="Cell"
                                outlined
                                class="mt-n6"
                                filled
                                disabled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="residenceVillages"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.apprehension
                                    .delinquentResidence.village.id
                                "
                                dense
                                color="grey"
                                label="Village"
                                outlined
                                class="mt-n6"
                                filled
                                disabled
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card max-width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>IV. Place of Apprehension</p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                :items="apprehensionProvinces"
                                item-text="name"
                                item-value="id"
                                v-model="
                                  editedDelinquent.apprehension
                                    .apprehensionPlace.province.id
                                "
                                dense
                                color="grey"
                                label="Province"
                                outlined
                                name="Apprehension Province"
                                class="mt-n6"
                                filled
                                disabled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Apprehension District"
                              >
                                <v-select
                                  :items="apprehensionDistricts"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.district.id
                                  "
                                  dense
                                  color="grey"
                                  label="District"
                                  outlined
                                  name="Apprehension District"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Apprehension Sector"
                              >
                                <v-select
                                  :items="apprehensionSectors"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.sector.id
                                  "
                                  dense
                                  color="grey"
                                  label="Sector"
                                  outlined
                                  name="Apprehension Sector"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Apprehension Cell"
                              >
                                <v-select
                                  :items="apprehensionCells"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.cell.id
                                  "
                                  dense
                                  color="grey"
                                  label="Cell"
                                  outlined
                                  name="Apprehension Cell"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Apprehension Village"
                              >
                                <v-select
                                  :items="apprehensionVillages"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.village.id
                                  "
                                  dense
                                  color="grey"
                                  label="Village"
                                  outlined
                                  name="Apprehension Village"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>
                                V. How he/she was apprehended and act he/she was
                                doing
                              </p>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="How apprehended"
                              >
                                <v-text-field
                                  dense
                                  label="How he/she was apprehended and act he/she was doing"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionWay
                                  "
                                  name="How apprehended"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                                dense
                                label="Deviant Acts"
                                type="text"
                                outlined
                                v-model="
                                  editedDelinquent.apprehension.deviantActs
                                "
                                name="Last Name"
                                color="grey"
                                class="mt-n6"
                                disabled
                                filled
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card max-width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>Additional Details</p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                                dense
                                label="Specific Disease"
                                type="text"
                                outlined
                                v-model="editedDelinquent.specificDisease"
                                color="grey"
                                class="mt-n6"
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="numeric"
                                name="Next Of Kin Phone"
                              >
                                <v-text-field
                                  dense
                                  label="Next Of Kin Phone"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.guardianPhone"
                                  name="Next Of Kin Phone"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="nida"
                              >
                                <v-select
                                  dense
                                  :items="homeless"
                                  label="Is Homeless"
                                  item-text="text"
                                  item-value="value"
                                  v-model="editedDelinquent.homeless"
                                  outlined
                                  name="Homeless"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Father Vital Status"
                              >
                                <v-select
                                  dense
                                  :items="vitalStatus"
                                  label="Father Vital Status"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                  v-model="editedDelinquent.fatherVitalStatus"
                                  name="Father Vital Status"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Mother Vital Status"
                              >
                                <v-select
                                  dense
                                  :items="vitalStatus"
                                  label="Mother Vital Status"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                  v-model="editedDelinquent.motherVitalStatus"
                                  name="Mother Vital Status"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Education"
                              >
                                <v-select
                                  dense
                                  :items="education"
                                  label="Education"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                  v-model="editedDelinquent.educationLevel"
                                  name="Education"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field
                                dense
                                label="Transfer State"
                                type="text"
                                outlined
                                v-model="editedDelinquent.latestTransfer.state"
                                color="grey"
                                disabled
                                filled
                              />
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>VI. Decision Taken</p>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-menu
                                ref="meetingDateMenu"
                                v-model="meetingDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="formattedMeetingDate"
                                    label="Meeting Date"
                                    outlined
                                    filled
                                    color="grey"
                                    class="mt-n6"
                                    dense
                                    readonly
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  ref="picker"
                                  v-model="meetingDate"
                                  :max="new Date().toISOString().substr(0, 10)"
                                  min="1900-01-01"
                                  no-title
                                  @change="saveMeetingDate"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Decision"
                              >
                                <v-select
                                  dense
                                  :items="decisions"
                                  label="Decision taken"
                                  item-text="name"
                                  item-value="name"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening.decision
                                  "
                                  name="Decision"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-select
                                dense
                                :items="transferCenters"
                                label="Select"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="
                                  editedDelinquent.latestScreening
                                    .transferCenter
                                "
                                name="Transfer Center"
                                color="grey"
                                filled
                                class="mt-n6"
                                v-if="transferCenters !== null"
                              />
                            </v-col>
                            <v-col v-if="periodOfTimeChoosen" cols="12" sm="4">
                              <v-menu
                                ref="periodOfTimeDateMenu"
                                v-model="periodOfTimeDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    v-model="formattedPeriodTimeDate"
                                    label="Rehabilitation End Date"
                                    outlined
                                    filled
                                    color="grey"
                                    class="mt-n6"
                                    dense
                                    readonly
                                    v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  ref="picker"
                                  v-model="periodOfTimeDate"
                                  :min="new Date().toISOString().substr(0, 10)"
                                  no-title
                                  @change="savePeriodTimeDate"
                                ></v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>VII. Reason for decision taken</p>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Reason"
                              >
                                <v-text-field
                                  dense
                                  label="Reason for decision taken"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .decisionReason
                                  "
                                  name="Reason"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>VIII. Screening Committee</p>
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position1"
                                color="grey"
                                @change="pushPosition1"
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position1Name"
                                color="grey"
                                filled
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position2"
                                color="grey"
                                @change="pushPosition2"
                                filled
                                v-if="position2NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position2Name"
                                color="grey"
                                filled
                                v-if="position2NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position3"
                                color="grey"
                                @change="pushPosition3"
                                filled
                                v-if="position3NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position3Name"
                                color="grey"
                                filled
                                v-if="position3NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position4"
                                color="grey"
                                @change="pushPosition4"
                                filled
                                v-if="position4NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position4Name"
                                color="grey"
                                filled
                                v-if="position4NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position5"
                                color="grey"
                                @change="pushPosition5"
                                filled
                                v-if="position5NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position5Name"
                                color="grey"
                                filled
                                v-if="position5NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position6"
                                color="grey"
                                @change="pushPosition6"
                                filled
                                v-if="position6NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position6Name"
                                color="grey"
                                filled
                                v-if="position6NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-select
                                dense
                                :items="positions"
                                label="Select Position"
                                item-text="name"
                                item-value="name"
                                outlined
                                v-model="position7"
                                color="grey"
                                @change="pushPosition7"
                                filled
                                v-if="position7NameField"
                              />
                            </v-col>
                            <v-col cols="12" sm="6" class="mt-n8">
                              <v-text-field
                                dense
                                label="Name"
                                type="text"
                                outlined
                                v-model="position7Name"
                                color="grey"
                                filled
                                v-if="position7NameField"
                              />
                            </v-col>
                            <v-btn
                              class="mx-2 mt-n6"
                              fab
                              x-small
                              @click="toggleCommittee"
                            >
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                            <v-btn
                              class="mx-2 mt-n6 red"
                              fab
                              x-small
                              v-if="position4NameField"
                              @click="removeCommittee"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <v-card width="1200" outlined raised class="mt-2">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <p>
                                IX. Opinion of the person received in transit
                                center, his/her parent or next of kin
                              </p>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Opinion"
                              >
                                <v-text-field
                                  dense
                                  label="Apprehendee opinion"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .delinquentPointOfView
                                  "
                                  name="Opinion"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                            <v-col cols="12" sm="12">
                              <validation-provider
                                v-slot="{ errors }"
                                rules="required"
                                name="Opinion"
                              >
                                <v-text-field
                                  dense
                                  label="Parent/Next Of Kin opinion"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .guardianPointOfView
                                  "
                                  name="Opinion"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                />
                              </validation-provider>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <p class="font-weight-thin font-italic mt-4">
                        Screened on:
                        <span>{{
                          new Date() | moment("dddd, MMMM Do YYYY")
                        }}</span>
                      </p>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="success ml-4 mb-4" text type="submit"
                      >Save</v-btn
                    >
                    <v-btn
                      class="mb-4"
                      color="blue darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editDelinquent(item)"
          v-if="item.latestScreening && showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="delinquentDialog(item)"
          v-if="item.latestScreening && showDelete"
        >
          mdi-delete
        </v-icon>
        <!-- <v-chip class="ma-2" color="green" text-color="white" > -->
        <v-chip
          small
          @click="screen(item)"
          v-if="!item.latestScreening && showCreate"
        >
          Screening
        </v-chip>
        <!-- </v-chip> -->
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this screening ?</p>
          <v-btn link small color="blue" @click="deleteDelinquent()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Delinquent from '@/models/Delinquent'
// import Location from '@/models/Location'
export default {
  data () {
    return {
      dialog: false,
      showCreate: false,
      showEdit: false,
      showDelete: false,
      deleteDialog: false,
      privileges: null,
      alertDialog: false,
      errorMessage1: null,
      centerTypes: [],
      transitId: null,
      rehabId: null,
      policeId: null,
      headQuarterId: null,
      postRehabId: null,
      headers: [
        {
          text: 'Delinquent Names',
          align: 'start',
          sortable: false,
          value: 'names'
        },
        {
          text: 'Apprehension District',
          value: 'apprehension.apprehensionPlace.district.name'
        },
        {
          text: 'Apprehension Sector',
          value: 'apprehension.apprehensionPlace.sector.name'
        },
        { text: 'Apprehension Date', value: 'apprehension.apprehensionDate' },
        { text: 'Elapsed Days', value: 'apprehension.elapsedDays' },
        { text: 'Decision', value: 'latestScreening.decision' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      delinquents: [],
      options: {
        page: null,
        itemsPerPage: null
      },
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      totalPages: 0,
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      homeless: [
        { text: 'yes', value: true },
        { text: 'no', value: false }
      ],
      position1: null,
      position2: null,
      position3: null,
      position4: null,
      position5: null,
      position6: null,
      position7: null,
      position1Name: null,
      position2Name: null,
      position3Name: null,
      position4Name: null,
      position5Name: null,
      position6Name: null,
      position7Name: null,
      position1NameField: false,
      position2NameField: false,
      position3NameField: false,
      position4NameField: false,
      position5NameField: false,
      position6NameField: false,
      position7NameField: false,
      birthProvinces: [],
      apprehensionProvinces: [],
      residenceProvinces: [],
      birthDistricts: [],
      apprehensionDistricts: [],
      residenceDistricts: [],
      birthSectors: [],
      apprehensionSectors: [],
      residenceSectors: [],
      birthCells: [],
      apprehensionCells: [],
      residenceCells: [],
      birthVillages: [],
      apprehensionVillages: [],
      residenceVillages: [],
      rehabilitationCenters: [],
      vitalStatus: ['ALIVE', 'DEAD'],
      editedIndex: -1,
      editedDelinquent: new Delinquent(),
      defaultItem: new Delinquent(),
      webcamPicture: null,
      nidaPicture: null,
      meetingDate: null,
      periodOfTimeDate: null,
      retrievedMeetingDate: null,
      meetingDateMenu: false,
      periodOfTimeDateMenu: false,
      errors: null,
      screening: false,
      delinquentItem: null,
      selectedPositions: [],
      committee: []
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Screening' : 'Screening'
    },
    formattedMeetingDate () {
      return this.meetingDate
        ? this.$moment(this.meetingDate).format('DD-MMM-YYYY')
        : this.retrievedMeetingDate
          ? this.$moment(this.retrievedMeetingDate).format('DD-MMM-YYYY')
          : ''
    },
    formattedPeriodTimeDate () {
      return this.periodOfTimeDate
        ? this.$moment(this.periodOfTimeDate).format('DD-MMM-YYYY')
        : this.retrievedMeetingDate
          ? this.$moment(this.retrievedMeetingDate).format('DD-MMM-YYYY')
          : ''
    },
    transferCenters () {
      return this.editedDelinquent.latestScreening.decision ===
        'Transfer the person in rehabilitation center'
        ? this.rehabilitationCenters
        : this.editedDelinquent.latestScreening.decision ===
          'Transfer the person to any other place for a dignified life re-integration'
          ? this.transitCenters
          : null
    },
    periodOfTimeChoosen () {
      return (
        this.editedDelinquent.latestScreening.decision ===
        'Rehabilitate the person in transit center for a short period'
      )
    },
    committeeMembers () {
      return Object.assign(
        ...this.selectedPositions.map((k, i) => ({ [k]: this.committee[i] }))
      )
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'search',
      'decisions',
      'positions',
      'education',
      'storeProvinces',
      'storeProvincesError'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadAlldelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAlldelinquents()
      }
    }
  },
  mounted () {
    this.loadAlldelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.loadBirthProvinces()
    if (this.decisions.length === 0) this.loadDecisions()
    this.loadRehabCenters()
    this.loadTransitCenters()
    if (this.positions.lenght === 0) this.loadCommitteeMembersPositions()
    if (this.education.length === 0) this.loadEducationLevels()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', [
      'loadDecisions',
      'loadCommitteeMembersPositions',
      'loadEducationLevels',
      'loadStoreProvinces'
    ]),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadAlldelinquents () {
      const pageNumber = this.page - 1
      this.$delinquentService
        .fetchAllTransfers(
          'screening_decision',
          null,
          null,
          null,
          'ADMITTED',
          pageNumber,
          this.delinquentsPerPage
        )
        .then(response => {
          this.totalPages = response.totalPages
          if (response.content) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach(element => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
              // if (element.latestScreening.approvalStatus === 'REQUESTED' || element.latestScreening.approvalStatus === 'APPROVED') {
              //   this.showEdit = false
              //   this.showDelete = false
              //   this.showCreate = false
              // }
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    editDelinquent (item) {
      this.delinquentItem = item
      this.editedIndex = this.delinquents.indexOf(item)
      this.$delinquentService
        .loadDelinquentIdentification(
          item.delinquent.id,
          'latest_apprehension,general_screening'
        )
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            const [
              position1,
              position2,
              position3,
              position4,
              position5,
              position6,
              position7
            ] = Object.keys(
              this.editedDelinquent.latestScreening.committeeMembers
            )
            const [
              position1Name,
              position2Name,
              position3Name,
              position4Name,
              position5Name,
              position6Name,
              position7Name
            ] = Object.values(
              this.editedDelinquent.latestScreening.committeeMembers
            )
            this.position1 = position1
            this.position2 = position2
            this.position3 = position3
            this.position4 = position4
            this.position5 = position5
            this.position6 = position6
            this.position7 = position7
            this.position1Name = position1Name
            this.position2Name = position2Name
            this.position3Name = position3Name
            this.position4Name = position4Name
            this.position5Name = position5Name
            this.position6Name = position6Name
            this.position7Name = position7Name
            this.toggleCommitteeEdit()
            this.retrievedDateOfBirth = response.dateOfBirth
            this.retrievedMeetingDate = response.latestScreening.meetingDate
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            this.webcamPicture = response.picture
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    screen (item) {
      this.delinquentItem = item
      this.editedIndex = this.delinquents.indexOf(item)
      this.$delinquentService
        .loadDelinquentIdentification(
          item.delinquent.id,
          'latest_apprehension,general_screening'
        )
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            this.retrievedDateOfBirth = response.dateOfBirth
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            this.webcamPicture = response.picture
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    delinquentDialog (item) {
      this.delinquentIndex = this.delinquents.indexOf(item)
      this.delinquentItem = item
      this.deleteDialog = true
      this.alertDialog = false
      this.alert = false
    },
    deleteDelinquent (item) {
      this.deleteDialog = false
      this.$delinquentService
        .deleteGeneralScreening(this.delinquentItem.latestScreening.id)
        .then(response => {
          if (response.status === 'success') {
            this.loadAlldelinquents()
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedDelinquent = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },

    // save () {
    //   if (((this.editedDelinquent.latestScreening.decision === 'Transfer the person in rehabilitation center' || this.editedDelinquent.latestScreening.decision === 'Transfer the person to any other place for a dignified life re-integration') && this.editedDelinquent.latestScreening.transferCenter !== null) || (this.editedDelinquent.latestScreening.decision !== 'Transfer the person in rehabilitation center' || this.editedDelinquent.latestScreening.decision !== 'Transfer the person to any other place for a dignified life re-integration')) {
    //     if (this.editedDelinquent.latestScreening.id) {
    //       this.updateDelinquent()
    //     } else this.createDelinquent()
    //   } else {
    //     this.setSnackbar(true)
    //     this.setColor('red')
    //     this.setText('Select Transfer Center')
    //   }
    // },
    onSubmit () {
      this.$refs.form.validate().then(success => {
        if (!success) {
          return
        }
        if (
          ((this.editedDelinquent.latestScreening.decision ===
            'Transfer the person in rehabilitation center' ||
            this.editedDelinquent.latestScreening.decision ===
              'Transfer the person to any other place for a dignified life re-integration') &&
            this.editedDelinquent.latestScreening.transferCenter !== null) ||
          this.editedDelinquent.latestScreening.decision !==
            'Transfer the person in rehabilitation center' ||
          this.editedDelinquent.latestScreening.decision !==
            'Transfer the person to any other place for a dignified life re-integration'
        ) {
          if (this.editedDelinquent.latestScreening.id) {
            this.updateDelinquent()
          } else this.createDelinquent()
        } else {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Select Transfer Center')
        }
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    updateDelinquent () {
      this.selectedPositions = [
        this.position1,
        this.position2,
        this.position3,
        this.position4,
        this.position5,
        this.position6,
        this.position7
      ]
      this.committee = [
        this.position1Name,
        this.position2Name,
        this.position3Name,
        this.position4Name,
        this.position5Name,
        this.position6Name,
        this.position7Name
      ]
      delete this.committeeMembers.undefined
      this.editedDelinquent.latestScreening.committeeMembers = this.committeeMembers
      this.editedDelinquent.latestScreening.meetingDate = this.formattedMeetingDate
      if (
        this.editedDelinquent.latestScreening.decision !==
          'Transfer the person in rehabilitation center' ||
        this.editedDelinquent.latestScreening.decision !==
          'Transfer the person to any other place for a dignified life re-integration'
      ) {
        this.editedDelinquent.latestScreening.transferCenter = null
      }
      if (this.periodOfTimeChoosen) {
        this.editedDelinquent.latestScreening.rehabilitationEndDate = this.formattedPeriodTimeDate
      }
      const deletedDelinquenArray = this.delinquents.splice(
        this.editedIndex,
        1
      )
      if (Object.keys(this.committeeMembers.length >= 3)) {
        this.$delinquentService
          .saveGeneralScreenings(this.editedDelinquent)
          .then(response => {
            if (response.id) {
              response.names =
                response.delinquent.firstName +
                ' ' +
                response.delinquent.lastName
              this.delinquents.splice(this.editedIndex, 0, response)
              this.close()
              this.editedDelinquent = new Delinquent()
              this.initPositions()
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.delinquents.splice(
                this.editedIndex,
                0,
                deletedDelinquenArray[0]
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText(
          'The screening committee is composed by 3 members at least'
        )
      }
    },
    createDelinquent () {
      this.selectedPositions = [
        this.position1,
        this.position2,
        this.position3,
        this.position4,
        this.position5,
        this.position6,
        this.position7
      ]
      this.committee = [
        this.position1Name,
        this.position2Name,
        this.position3Name,
        this.position4Name,
        this.position5Name,
        this.position6Name,
        this.position7Name
      ]
      delete this.committeeMembers.null
      this.editedDelinquent.latestScreening.committeeMembers = this.committeeMembers
      this.editedDelinquent.latestScreening.meetingDate = this.formattedMeetingDate
      if (Object.keys(this.committeeMembers).length >= 3) {
        this.$delinquentService
          .saveGeneralScreenings(this.editedDelinquent)
          .then(response => {
            if (response.id) {
              this.delinquentItem.latestScreening = this.editedDelinquent.latestScreening
              this.close()
              this.editedDelinquent = new Delinquent()
              this.initPositions()
            } else {
              // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText(
          'The screening committee is composed by 3 members at least'
        )
      }
    },
    fetchBirthSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.placeOfBirth)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.birthDistricts,
              this.birthSectors,
              this.birthCells,
              this.birthVillages
            ] = response
          }
        })
    },
    fetchApprehensionSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.apprehensionDistricts,
              this.apprehensionSectors,
              this.apprehensionCells,
              this.apprehensionVillages
            ] = response
          }
        })
    },
    fetchResidenceSiblings () {
      this.$locationService
        .fetchAllSiblings(
          this.editedDelinquent.apprehension.delinquentResidence
        )
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.residenceDistricts,
              this.residenceSectors,
              this.residenceCells,
              this.residenceVillages
            ] = response
          }
        })
    },
    saveMeetingDate (meetingDate) {
      this.$refs.meetingDateMenu.save(meetingDate)
    },
    savePeriodTimeDate (periodOfTimeDate) {
      this.$refs.periodOfTimeDateMenu.save(periodOfTimeDate)
    },
    loadRehabCenters () {
      // this.$centerService.fetchAll(null, null, this.rehabId)
      this.$centerService.fetchAllRehabCenters().then(response => {
        if (response.content) {
          this.rehabilitationCenters = response.content
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('Rehabilitation Centers: ' + response.message)
        }
      })
    },
    loadTransitCenters () {
      this.$centerService
        .fetchAll(null, null, this.transitId)
        .then(response => {
          if (response.content) {
            this.transitCenters = response.content
          } else {
            this.$authenticationService.checkAuthentication(
              response.status,
              this.$router,
              this.$store
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Transit Centers: ' + response.message)
          }
        })
    },
    loadCenterType () {
      this.$centerService.fetchAllCenterType().then(response => {
        if (!response.status) {
          this.centerTypes = response
          this.centerTypes.forEach(element => {
            if (element.type === 'TRANSIT') {
              this.transitId = element.id
            } else if (element.type === 'REHABILITATION') {
              this.rehabId = element.id
            } else if (element.type === 'POLICE_STATION') {
              this.policeId = element.id
            } else if (element.type === 'NRS_HEADQUARTERS') {
              this.headQuarterId = element.id
            } else if (element.type === 'POST_REHABILITATION') {
              this.postRehabId = element.id
            }
          })
        } else {
          this.$authenticationService.checkAuthentication(
            response.status,
            this.$router,
            this.$store
          )
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    checkDuplicates (arr) {
      return new Set(arr).size !== arr.length
    },
    pushPosition7 () {
      if (this.position7 && !this.selectedPositions.includes(this.position7)) {
        this.selectedPositions[6] = this.position7
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position7 = null
        this.position7Name = null
        this.position7NameField = false
        this.selectedPositions.splice(6, 1)
      }
    },
    pushPosition6 () {
      if (this.position6 && !this.selectedPositions.includes(this.position6)) {
        this.selectedPositions[5] = this.position6
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position6 = null
        this.position7 = null
        this.position6Name = null
        this.position7Name = null
        this.position6NameField = false
        this.position7NameField = false
        this.selectedPositions.splice(5, 2)
      }
    },
    pushPosition5 () {
      if (this.position5 && !this.selectedPositions.includes(this.position5)) {
        this.selectedPositions[4] = this.position5
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position5 = null
        this.position6 = null
        this.position7 = null
        this.position5Name = null
        this.position6Name = null
        this.position7Name = null
        this.position5NameField = false
        this.position6NameField = false
        this.position7NameField = false
        this.selectedPositions.splice(4, 3)
      }
    },
    pushPosition4 () {
      this.errorMessage1 = null
      if (this.position4 && !this.selectedPositions.includes(this.position4)) {
        this.selectedPositions[3] = this.position4
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position4 = null
        this.position5 = null
        this.position6 = null
        this.position7 = null
        this.position4Name = null
        this.position5Name = null
        this.position6Name = null
        this.position7Name = null
        this.position4NameField = false
        this.position5NameField = false
        this.position6NameField = false
        this.position7NameField = false
        this.selectedPositions.splice(3, 4)
      }
    },
    pushPosition3 () {
      this.errorMessage1 = null
      if (this.position3 && !this.selectedPositions.includes(this.position3)) {
        this.selectedPositions[2] = this.position3
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position3 = null
        this.position4 = null
        this.position5 = null
        this.position6 = null
        this.position7 = null
        this.position3Name = null
        this.position4Name = null
        this.position5Name = null
        this.position6Name = null
        this.position7Name = null
        this.position3NameField = false
        this.position4NameField = false
        this.position5NameField = false
        this.position6NameField = false
        this.position7NameField = false
        this.selectedPositions.splice(2, 5)
      }
    },
    pushPosition2 () {
      this.errorMessage1 = null
      if (this.position2 && !this.selectedPositions.includes(this.position2)) {
        this.selectedPositions[1] = this.position2
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position2 = null
        this.position3 = null
        this.position4 = null
        this.position5 = null
        this.position6 = null
        this.position7 = null
        this.position2Name = null
        this.position3Name = null
        this.position4Name = null
        this.position5Name = null
        this.position6Name = null
        this.position7Name = null
        this.position2NameField = false
        this.position3NameField = false
        this.position4NameField = false
        this.position5NameField = false
        this.position6NameField = false
        this.position7NameField = false
        this.selectedPositions.splice(1, 6)
      }
    },
    pushPosition1 () {
      this.errorMessage1 = null
      if (this.position1 && !this.selectedPositions.includes(this.position1)) {
        this.selectedPositions[0] = this.position1
      } else {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'red'
        this.position1 = null
        this.position2 = null
        this.position3 = null
        this.position4 = null
        this.position5 = null
        this.position6 = null
        this.position7 = null
        this.position1Name = null
        this.position2Name = null
        this.position3Name = null
        this.position4Name = null
        this.position5Name = null
        this.position6Name = null
        this.position7Name = null
        this.position2NameField = false
        this.position3NameField = false
        this.position4NameField = false
        this.position5NameField = false
        this.position6NameField = false
        this.position7NameField = false
        this.selectedPositions.splice(0, 7)
      }
    },
    initPositions () {
      this.position1 = null
      this.position2 = null
      this.position3 = null
      this.position4 = null
      this.position5 = null
      this.position6 = null
      this.position7 = null
      this.position1Name = null
      this.position2Name = null
      this.position3Name = null
      this.position4Name = null
      this.position5Name = null
      this.position6Name = null
      this.position7Name = null
      this.position2NameField = false
      this.position3NameField = false
      this.position4NameField = false
      this.position5NameField = false
      this.position6NameField = false
      this.position7NameField = false
    },
    toggleCommittee () {
      if (this.position1 && this.position1Name) {
        this.position2NameField = true
      }
      if (this.position2 && this.position2Name) {
        this.position3NameField = true
      }
      if (this.position3 && this.position3Name) {
        this.position4NameField = true
      }
      if (this.position4 && this.position4Name) {
        this.position5NameField = true
      }
      if (this.position5 && this.position5Name) {
        this.position6NameField = true
      }
      if (this.position6 && this.position7Name) {
        this.position7NameField = true
      }
      if (this.position7 && this.position7Name) {
        this.snackbar = true
        this.text = 'Duplicated positions are not allowed'
        this.color = 'orange'
      }
    },
    removeCommittee () {
      if (this.position7 || this.position7Name) {
        this.position7 = null
        this.position7Name = null
        this.position7NameField = false
      } else if (this.position6 && this.position6Name) {
        this.position6 = null
        this.position6Name = null
        this.position6NameField = false
      } else if (this.position5 && this.position5Name) {
        this.position5 = null
        this.position5Name = null
        this.position5NameField = false
      } else {
        this.position4 = null
        this.position4Name = null
        this.position4NameField = false
      }
    },
    toggleCommitteeEdit () {
      if (this.position1) {
        this.position1NameField = true
      }
      if (this.position2) {
        this.position2NameField = true
      }
      if (this.position3) {
        this.position3NameField = true
      }
      if (this.position4) {
        this.position4NameField = true
      }
      if (this.position5) {
        this.position5NameField = true
      }
      if (this.position6) {
        this.position6NameField = true
      }
      if (this.position7) {
        this.position7NameField = true
      }
    },
    checkPrivileges () {
      this.privileges.indexOf('CREATE_GENERALSCREENINGS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.indexOf('UPDATE_GENERALSCREENINGS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_GENERALSCREENINGS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
